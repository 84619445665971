<template>
    <div class="homePageTotal" @click="toggleSearchEngine" @contextmenu="disableContextMenu">
        <div class="homePageBg" :class="{'isFocus':isClick === true}" @click="toggleBlue"></div>
        <div class="homePageSearchClock" @click="changeClockStyle(2)" v-if="clockNum===1">{{parseTime4(time)
            }}
        </div>
        <div class="homePageSearchClock" @click="changeClockStyle(1)" v-if="clockNum===2">{{
                parseTime5(time)
            }}
        </div>
        <div class="homePageSearch">
            <div style="display: flex">
                <div class="searchEngineIcon" v-show="isClick">
                    <img :src="useEngine.icon" @click="choseEngine()">
                </div>
                <input
                        placeholder="搜索"
                        class="homePageSearchInput"
                        :class="{'havaInfo': searchInfo !== null && searchInfo !== ''}"
                        v-model="searchInfo"
                        @focus="isFocus()"
                        @keyup.enter="search">
                <i class="searchIcon" v-show="isClick">
                    <img src="@/assets/img/searchIcon.png" @click="search()">
                </i>
            </div>
            <div class="searchEngine" v-if="isShowSearchEngine">
                <div v-for="item in searchEngine">
                    <div class="searchEngineList" @click="changeEngine(item)">
                        <img :src="item.icon">
                        <div>{{ item.name }}</div>
                    </div>
                </div>
            </div>
            <div class="searchTips"
                 :class="{'notShow': searchInfo === null || searchInfo === '' || searchInfo.replaceAll(' ','')===''}">
                <div v-for="item in tips">
                    <div class="searchTip" @click="search2(item)">{{ item }}</div>
                </div>
            </div>
        </div>
        <div class="label" v-if=" (searchInfo === null || searchInfo === '' || searchInfo.replaceAll(' ','')==='') && !isShowSearchEngine">
            <div v-for="row in page" :key="row" @click="goto(row.path)" class="menu"
                 :style="`background-image: url(${row.background})`"> {{ row.name }}
            </div>
        </div>
    </div>
</template>

<script>
import {parseTime2, parseTime4, parseTime5} from "@/utils/parse";
import {searchTips} from "@/api/yunoApi";
import {goto} from "@/utils/pathUtil";

export default {
    name: "homePage",
    data() {
        return {
            page: [
                {'name': '云顶阵容', 'path': 'lineup', 'background': require('@/assets/img/cover/10110.jpg')},
                {'name': '答案之书', 'path': 'answer', 'background': require('@/assets/img/cover/answerBook.jpeg')},
                {
                    'name': '星座运势️',
                    'path': 'constellation',
                    'background': require('@/assets/img/cover/universe.png')
                },
                {'name': '算命轮盘', 'path': 'gossip', 'background': require('@/assets/img/cover/gossip.png')},
                {'name': '记账本', 'path': 'bill', 'background': require('@/assets/img/cover/bill.png')},
                {'name': '文件转发', 'path': 'postal', 'background': require('@/assets/img/cover/mail.png')},
                {'name': '小纸条', 'path': 'notes', 'background': require('@/assets/img/cover/note.jpeg')},
                {'name': '人物大全', 'path': 'characters', 'background': require('@/assets/img/cover/ultraman.png')},
                {'name': '每天吃什么', 'path': 'eat', 'background': require('@/assets/img/cover/food.png')},
                {'name': '待办事项', 'path': 'todoList', 'background': require('@/assets/img/cover/todoList.png')},
                {'name': '快捷复制器', 'path': 'copy', 'background': require('@/assets/img/cover/copy.png')},
                {'name': '余额截图', 'path': 'wechat', 'background': require('@/assets/img/cover/noMoney.png')},
                {'name': '十万个不为什么', 'path': 'question', 'background': require('@/assets/img/cover/whyBg.png')},
                {'name': '刑法大全', 'path': 'law', 'background': require('@/assets/img/cover/law.png')},
                {'name': '牌', 'path': 'introductory', 'background': require('@/assets/img/cover/balckSun.png')},
                {'name': '每天100个单词', 'path': 'word', 'background': require('@/assets/img/cover/dictionary.png')},
                {'name': '老虎机', 'path': 'machine', 'background': require('@/assets/img/cover/jackpot.png')},
                {'name': '表格统计器', 'path': 'xls', 'background': require('@/assets/img/cover/xls.png')},
                {'name': '二维码生成器', 'path': 'QRCode', 'background': require('@/assets/img/cover/QRCode.png')},
            ],
            clockNum: 1,
            useEngine: {
                name: '百度',
                icon: require('@/assets/img/icon/baidu.png'),
                url: 'https://www.baidu.com/s?ie=utf-8&word='
            },
            selectedEngine: 1,
            isClick: false,
            tips: [],
            time: new Date(),
            isShowSearchEngine: false,
            searchInfo: null,
            timer: null, // 添加定时器变量
            searchEngine: [
                {
                    name: '百度',
                    icon: require('@/assets/img/icon/baidu.png'),
                    url: 'https://www.baidu.com/s?ie=utf-8&word='
                },
                {name: '360', icon: require('@/assets/img/icon/360.png'), url: 'https://www.so.com/s?q='},
                {name: '搜狗', icon: require('@/assets/img/icon/sougou.png'), url: 'https://www.sogou.com/web?query='},
                {name: '谷歌', icon: require('@/assets/img/icon/google.png'), url: 'https://www.google.com/search?q='},
                {name: 'Bing', icon: require('@/assets/img/icon/bing.png'), url: 'https://cn.bing.com/search?q='},
                {
                    name: 'Yahoo',
                    icon: require('@/assets/img/icon/yahoo.png'),
                    url: 'https://search.yahoo.com/search?p='
                },
            ]

        }
    },
    methods: {
        parseTime4,
        goto,
        toggleBlue() {
            this.searchInfo = ''
            this.isClick = false
        },
        parseTime2,
        changeClockStyle(value) {
            this.clockNum = value
        },
        disableContextMenu(event) {
            // 阻止默认的上下文菜单显示
            event.preventDefault();
        },
        toggleSearchEngine() {
            this.isShowSearchEngine = false;
        },
        isFocus() {
            event.stopPropagation(); // 阻止事件冒泡
            this.searchInfo = ' '
            this.isClick = true
        },
        changeEngine(item) {
            event.stopPropagation(); // 阻止事件冒泡
            this.useEngine = item
            this.isShowSearchEngine = false
        },
        choseEngine() {
            event.stopPropagation(); // 阻止事件冒泡
            this.isShowSearchEngine = !this.isShowSearchEngine
        },
        search() {
            if (this.searchInfo === null || this.searchInfo === '') {
                return
            } else {
                let url = this.useEngine.url + this.searchInfo
                window.open(url, '_blank');
            }
        },
        search2(item) {
            this.searchInfo = item
            let url = this.useEngine.url + this.searchInfo
            window.open(url, '_blank');
        },
        searchTips() {
            searchTips(this.searchInfo).then(res => {
                res = res.toString().replaceAll('suggestionsHandler(', '').replaceAll(');', '');
                res = res.replaceAll("\"", '\'')
                res = res.substring(res.indexOf('[') + 1, res.indexOf(']')).replaceAll('\'', '')
                this.tips = res.split(',')
            })
        },
        parseTime5
    },
    watch: {
        // 观察 searchInfo 数据的变化
        searchInfo(newVal, oldVal) {
            // 当 searchInfo 发生变化时调用 searchTips 方法
            this.searchTips(newVal);
        }
    },
    mounted() {
        this.timer = setInterval(() => {
            this.time = new Date(); // 每秒更新时间
        }, 1000);
    },
    beforeDestroy() {
        clearInterval(this.timer); // 清除定时器，防止内存泄漏
    }
}
</script>

<style scoped>
@import "@/views/css/homePage.css";
</style>