<template>
    <div class="table-container">
        <div class="gotoWiki" @click="goto('wiki')">← 前往常用介绍</div>
        <div ref="elementToSave" class="xls" :style="{ width: 300+(tableData.length * 200) + 'px' }">
            <div class="xlsTitle">
                <div style="text-align: right;flex: 1">{{ getTime3() }}</div>
                <input v-model="name" type="text" style="color: red;text-align: left;flex: 1" class="city-input"
                       @focus="clickDate()"
                       @blur="leaveDate()"
                       :placeholder="isInput === true ? '' : '姓名'">

            </div>
            <div class="xls2ndTitle">
                <div class="xls2ndTitleSon">数据</div>
                <div class="xls2ndTitleSon" v-for="item in tableData">
                    <input v-model="item.cityName" type="text" style="color: red" class="city-input"
                           @focus="clickDate()"
                           @blur="leaveDate()"
                           :placeholder="isInput === true ? '' : '城市'"></div>
            </div>
            <div class="info">
                <div class="sideTitle">页面咨询</div>
                <div class="rightSideInfo" v-for="item in tableData">
                    <input v-model="item.pageInquiry" type="text" style="color: black" class="city-input"
                           @focus="clickDate()"
                           @blur="leaveDate()"
                           :placeholder="isInput === true ? '' : '0'"></div>
            </div>
            <div class="info">
                <div class="sideTitle">电话咨询</div>
                <div class="rightSideInfo" v-for="item in tableData">
                    <input v-model="item.phoneInquiry"
                           type="text"
                           style="color: black"
                           class="city-input"
                           @focus="clickDate()"
                           @blur="leaveDate()"
                           :placeholder="isInput === true ? '' : '0'">
                </div>

            </div>
            <div class="info">
                <div class="sideTitle">微信加粉</div>
                <div class="rightSideInfo" v-for="item in tableData">
                    <input v-model="item.wechatAdd" type="text" style="color: black" class="city-input"
                           @focus="clickDate()"
                           @blur="leaveDate()"
                           :placeholder="isInput === true ? '' : '0'">
                </div>
            </div>
            <div class="info">
                <div class="sideTitle">当天成交</div>
                <div class="rightSideInfo" v-for="item in tableData">
                    <input v-model="item.dayDeal" type="text" style="color: black" class="city-input"
                           @focus="clickDate()"
                           @blur="leaveDate()"
                           :placeholder="isInput === true ? '' : '0'">
                </div>
            </div>
            <div class="info">
                <div class="sideTitle">老粉成交</div>
                <div class="rightSideInfo" v-for="item in tableData">
                    <input v-model="item.oldDeal" type="text" style="color: black" class="city-input"
                           @focus="clickDate()"
                           @blur="leaveDate()"
                           :placeholder="isInput === true ? '' : '0'">
                </div>
            </div>
            <div class="info">
                <div class="sideTitle">直接预约</div>
                <div class="rightSideInfo" v-for="item in tableData">
                    <input v-model="item.buyDirectly" type="text" style="color: black" class="city-input"
                           @focus="clickDate()"
                           @blur="leaveDate()"
                           :placeholder="isInput === true ? '' : '0'"></div>
            </div>
            <div class="info">
                <div class="sideTitle">合计成交金额</div>
                <div class="rightSideInfo" v-model="totalAmount" :style="{ flex: tableData.length }">
                    <input v-model="totalAmount" type="text" style="color: black" class="city-input"
                           @focus="clickDate()"
                           @blur="leaveDate()"
                           :placeholder="isInput === true ? '' : '0'">
                </div>
            </div>
            <div class="info">
                <div class="sideTitle">合计加粉率</div>
                <div class="rightSideInfo" :style="{ flex: tableData.length }">{{ addRate }}</div>
            </div>
            <div class="info">
                <div class="sideTitle">合计转化率</div>
                <div class="rightSideInfo" :style="{ flex: tableData.length }">{{ conversionRate }}</div>
            </div>
            <div style="display: flex;margin-top: 10px;gap: 20px">
                <div class="buttonA" style="background: green;color: white" @click="addCity()">添加城市</div>

                <div class="buttonC" style="background: #3996fd;color: white;" @click="calculationResults()">
                    计算结果
                </div>
                <div class="buttonD" style="background: #2d2529;color: white" @click="save()">保存</div>
                <div class="buttonB" style="background: red;color: white" @click="clean()">清空</div>
            </div>
            <div style="margin-top: 100px;font-size: 20px;text-align: left">
                <div>1.多个城市先添加城市，然后正常填数据</div>
                <div>2.不用计算电话咨询和直接预约关系</div>
                <div>3.日期不用写，填上名字和城市这些，数据填好了以后再去计算结果</div>
                <div>4.计算完结果后调整数据不需要去更正电话咨询与直接预约关系</div>
                <div>5.最后检查一下，没问题就保存就可以了</div>
            </div>
        </div>
        <div>
            <!-- 隐藏的文件输入 -->
            <input type="file" ref="fileInput" @change="handleFileChange" style="display: none">
            <!-- 拖拽上传区域 -->
            <div
                    class="drop-zone"
                    @dragover.prevent
                    @drop.prevent="handleDrop"
                    @click="handleImageClick"
            >
                <p>券码二维码解码，支持粘贴/托拽/上传图片进来</p>

            </div>
            <div v-if="decodedData"
                 style="text-align: left;border: 2px #a09dff solid;padding:20px;cursor: pointer;margin: 0 50px 0 50px;border-radius: 10px"
                 @click="copyText(decodedData)">
                <div>券码⬇</div>
                <div style="font-size: larger;font-weight: bolder;color: red;text-align: center">{{ decodedData }}</div>
                <div>点击直接复制</div>
            </div>
            <div v-if="decodedData"
                 style="text-align: center;border: 1px #f4ff53 dashed;padding: 10px;cursor: pointer;width: 30%;margin: 10px 30% 0 30%;background-color: red;color: white"
                 @click="clearImg()">
                清空
            </div>
        </div>
        <div v-if="imageSrc" style="margin-top: 40px">
            <img :src="imageSrc" alt="Uploaded Image"/>
        </div>
    </div>

</template>

<script>
import {getTime2, getTime3, getTimeyyyyMMddHHmm} from "../utils/parse";
import domtoimage from 'dom-to-image';
import jsQR from 'jsqr';
import {goto} from "@/utils/pathUtil";

export default {
    name: "xls",
    methods: {
        goto,
        getTime3,
        clearImg() {
            this.decodedData = null
            this.imageSrc = null
        },
        copyText(text) {
            const input = document.createElement('input');
            input.value = text;
            document.body.appendChild(input);
            input.select();
            document.execCommand('copy');
            document.body.removeChild(input);
            alert("复制成功：  " + text)
        },
        addCity() {
            const cityDate = {
                cityName: '',
                pageInquiry: null,
                phoneInquiry: null,
                wechatAdd: null,
                dayDeal: null,
                oldDeal: null,
                buyDirectly: null,
            }
            if (this.tableData.length <= 3) {
                this.tableData.push(cityDate)
            } else {
                alert("4个城市还不够？满了")
            }
        },
        handleImageClick() {
            this.$refs.fileInput.click();
        },
        handleDragOver(event) {
            event.preventDefault();
        },
        handleDrop(event) {
            event.preventDefault();
            this.handleFiles(event.dataTransfer.files);
        },
        handleFileChange(event) {
            this.handleFiles(event.target.files);
        },
        handleFiles(files) {
            const file = files[0];
            if (file) {
                this.handleImageUpload(file)
            }
        },
        handlePaste(event) {
            const items = (event.clipboardData || event.originalEvent.clipboardData).items;
            const item = items[0]
            if (item.kind === 'file' && item.type.startsWith('image/')) {
                const blob = item.getAsFile();
                this.handleImageUpload(blob);
                event.preventDefault();
            }
        },
        handleImageUpload(file) {
            this.decodedData = null
            if (file) {
                const reader = new FileReader();
                reader.onload = (e) => {
                    this.imageSrc = e.target.result; // Set the image source to display the image

                    const imageData = new Image();
                    imageData.onload = () => {
                        const canvas = document.createElement('canvas');
                        const context = canvas.getContext('2d');

                        canvas.width = imageData.width;
                        canvas.height = imageData.height;
                        context.drawImage(
                            imageData,
                            0,
                            0,
                            imageData.width,
                            imageData.height
                        );

                        const imageDataArray = context.getImageData(
                            0,
                            0,
                            imageData.width,
                            imageData.height
                        ).data;
                        const code = jsQR(imageDataArray, imageData.width, imageData.height);

                        if (code) {
                            const qrCodeData = code.data;
                            this.decodedData = qrCodeData;
                            // You can now use qrCodeData as needed
                        } else {
                            alert("这个图里有二维码吗？");
                        }
                    };

                    imageData.src = e.target.result;
                };
                reader.readAsDataURL(file);
            }
        },
        clickDate() {
            this.isInput = true
        },
        leaveDate() {
            this.isInput = false
        },
        setSeo() {
            // 设置关键词标签
            let keywordsContent = '表格';
            let keywordsMeta = document.querySelector('meta[name="keywords"]');
            if (!keywordsMeta) {
                keywordsMeta = document.createElement('meta');
                keywordsMeta.setAttribute('name', 'keywords');
                document.head.appendChild(keywordsMeta);
            }
            keywordsMeta.setAttribute('content', keywordsContent);

            // 设置描述标签
            let descriptionContent = '舜心表格。';
            let descriptionMeta = document.querySelector('meta[name="description"]');
            if (!descriptionMeta) {
                descriptionMeta = document.createElement('meta');
                descriptionMeta.setAttribute('name', 'description');
                document.head.appendChild(descriptionMeta);
            }
            descriptionMeta.setAttribute('content', descriptionContent);

            // 设置标题
            document.title = '舜心每日数据表格。';
        },
        calculationResults() {
            if (!this.isAdded) {
                this.tableData.forEach(v => {
                    v.phoneInquiry = Number(v.phoneInquiry) + Number(v.buyDirectly);
                });
                this.isAdded = true
            }
            let defaultValue = 0
            let wechatValue = 0
            let calculationValue = 0
            this.tableData.forEach(v => {
                defaultValue += Number(v.pageInquiry) + Number(v.phoneInquiry)
                wechatValue += Number(v.wechatAdd)
                calculationValue += (Number(v.dayDeal) - Number(v.oldDeal))
            });
            this.addRate = Math.ceil((wechatValue / defaultValue) * 100).toFixed(0) + '%';
            this.conversionRate = Math.ceil((calculationValue / defaultValue) * 100).toFixed(0) + '%';
        },
        clean() {
            location.reload();
        },
        save() {
            const element = this.$refs.elementToSave;
            domtoimage.toPng(element)
                .then((dataUrl) => {
                    const link = document.createElement('a');
                    link.download = getTimeyyyyMMddHHmm() + '.png';
                    link.href = dataUrl;
                    link.click();
                })
                .catch((error) => {
                    console.error('oops, something went wrong!', error);
                });
        },
    }
    ,
    mounted() {
        this.setSeo()
        document.addEventListener('paste', this.handlePaste);
    },
    beforeDestroy() {
        document.removeEventListener('paste', this.handlePaste);
    },
    data() {
        return {
            imageSrc: null,
            decodedData: null,
            name: '',
            imageUrl: null,
            isAdded: false,
            totalAmount: '',
            isInput: false,
            addRate: '',
            conversionRate: '',
            tableData: [
                {
                    cityName: '',
                    pageInquiry: null,
                    phoneInquiry: null,
                    wechatAdd: null,
                    dayDeal: null,
                    oldDeal: null,
                    buyDirectly: null,
                },
            ]
        };
    }
}
;
</script>

<style scoped>
.gotoWiki {
    position: fixed;
    padding: 10px;
    background: red;
    border-radius: 15px;
    color: white;
    cursor: pointer;
    display: flex; /* 使用flex布局 */
    justify-content: center; /* 水平居中 */
    align-items: center; /* 垂直居中 */
    text-align: center; /* 确保文本在flex容器中居中 */
    text-decoration: none; /* 如果是链接，去除下划线 */
    transition: transform 0.3s, box-shadow 0.3s; /* 平滑过渡效果 */
}

.gotoWiki:hover {
    box-shadow: 3px 4px 8px rgba(86, 77, 77, 0.7);
    transform: scale(0.95); /* 使用transform来缩放，避免改变元素尺寸 */
}
.table-container {
    display: flex;

}

.xls {
    margin-top: 50px;
    background: white;
    padding: 10px 10px 10px 10px;
    border-radius: 2px;

}

.drop-zone {
    margin: 50px 50px 10px 50px;
    cursor: pointer;
    border: 3px dashed #ccc;
    padding: 100px;
    text-align: center;

}

.city-input, .info-input {
    margin-top: -5px;
    font-weight: 300;
    font-size: 30px;
    width: 100%;
    text-align: center;
    border: none;
    background: transparent;
}

.city-input:focus, .info-input:focus {
    outline: none;
    border: 1px solid #ccc;
}

.xlsTitle {
    display: flex;
    background: yellow;
    text-align: center;
    font-size: 30px;
    font-weight: lighter;
    color: red;
    border: black 1px solid;
    letter-spacing: 2px; /* 增加文字间隔 */
}

.xls2ndTitle, .info {
    font-family: 宋体;
    display: flex;
}

.xls2ndTitleSon {
    flex: 1;
    background: yellow;
    text-align: center;
    font-size: 30px;
    font-weight: lighter;
    color: red;
    border: black 1px solid;
    letter-spacing: 2px; /* 增加文字间隔 */
}

.sideTitle {
    flex: 1;
    text-align: left;
    font-size: 30px;
    font-weight: normal;
    font-family: 宋体;
    border: black 1px solid;
    letter-spacing: 2px; /* 增加文字间隔 */
}

.rightSideInfo {
    flex: 1;
    font-family: "fangsong";
    text-align: center;
    font-size: 30px;
    font-weight: lighter;
    border: black 1px solid;
}

.buttonA, .buttonB, .buttonC, .buttonD {
    flex: 1;
    border-radius: 10px;
    cursor: pointer;
    font-size: 22px;
    height: 40px;
}

.buttonA:hover, .buttonB:hover, .buttonC:hover, .buttonD:hover {
    height: 40px;
    font-size: 30px;
    transform: scale(0.98);
    box-shadow: 8px 10px 12px rgba(86, 77, 77, 0.5); /* 更深的阴影 */
    transition: all 0.3s ease; /* 平滑过渡效果 */
}

</style>