<template>
    <div>
        <div class="gotoXls" @click="goto('xls')">←前往表格/券码读取</div>
        <div v-if="!isAdd">
            <div class="search">
                <input v-model="searchKeyword" placeholder="搜索" @keyup.enter="getWiki">
                <img src="@/assets/img/search.png" class="searchIcon" @click="getWiki">
                <img src="@/assets/img/undo.png" class="clearIcon" @click="clear()" v-if="searchKeyword">
            </div>
            <div class="box_container">
                <div v-for="item in wikiList" :key="item.id" class="box" @click="copyText(item.interpretation)">
                    <div class="word"> {{ item.word }}</div>
                    <div class="interpretation">{{ item.interpretation }}</div>
                </div>
            </div>
        </div>
        <div v-if="isAdd">
            <div class="addTitle">
                <input v-model="title" placeholder="输入标题">
            </div>
            <div class="addInfo">
                <textarea v-model="info" placeholder="输入内容"></textarea>
            </div>
        </div>
        <div class="create-button" style="background: red" @click="addWiki()" v-if="!isAdd">+</div>
        <div class="create-button" style="background: #42b983" @click="confirmAddWiki()" v-if="isAdd">✓</div>
    </div>
</template>

<script>
import {addHousekeepingWiki, getHousekeepingWiki} from "@/api/yunoApi";
import {goto} from "@/utils/pathUtil";

export default {
    name: "wiki",
    data() {
        return {
            isAdd: false,
            wikiList: [],
            searchKeyword: '',
            title: '',
            info: ''
        }
    }, methods: {
        goto,
        confirmAddWiki() {
            if (this.title !== '' && this.info !== '') {
                const word = {word: this.title, interpretation: this.info}
                addHousekeepingWiki(word).then(res => {
                    setTimeout(() => {
                    }, 500);
                })
            }
            this.title = ''
            this.info = ''
            this.isAdd = false
            this.clear()
        },
        addWiki() {
            this.isAdd = true
        },
        clear() {
            this.searchKeyword = ''
            this.getWiki()
        },
        getWiki() {
            const word = {id: 0}
            if (this.searchKeyword !== '') {
                word.info = this.searchKeyword
            }
            getHousekeepingWiki(word).then(res => {
                this.wikiList = res
            })
        }, copyText(text) {
            if (text.includes('https://www.')) {
                window.open(text, '_blank');
            } else {
                const input = document.createElement('input');
                input.value = text;
                document.body.appendChild(input);
                input.select();
                document.execCommand('copy');
                document.body.removeChild(input);
            }
        },
    },
    mounted() {
        this.getWiki()
    }
}
</script>

<style scoped>
.addTitle input {
    margin-top: -5px;
    font-weight: 300;
    font-size: 26px;
    text-align: left;
    margin-right: 20px;
}

.addInfo textarea {
    margin-top: 30px;
    width: 80vw;
    height: 70vh;
    font-weight: 300;
    font-size: 26px;
    text-align: left;
    margin-right: 20px;
}

.searchIcon {
    scale: 0.65;
    margin-top: -5px;
    position: absolute;
}

.clearIcon {
    scale: 0.5;
    margin-top: -25px;
    position: absolute;
}

.search {
    cursor: pointer;
    padding-right: 100px;
    text-align: right;
    margin-top: 30px;
    margin-bottom: 30px;
}

.search input {
    margin-top: -5px;
    font-weight: 300;
    font-size: 22px;
    text-align: left;
    margin-right: 20px;
}

.box_container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    gap: 20px;
}

.box {
    width: calc(25% - 20px);
    box-sizing: border-box;
    border: #fcfcfc 1px solid;
    box-shadow: 3px 4px 8px rgba(86, 77, 77, 0.7);
    padding: 10px;
}

.box:hover {
    scale: 0.99;
    cursor: pointer;
}

.word {
    display: flex;
    color: #a10000;
    font-weight: bold;
    font-size: 20px;
}

.interpretation {
    padding: 10px;
    white-space: pre-wrap; /* 保留原始格式并正常换行 */
    word-wrap: break-word; /* 长单词或URL断行 */
    text-align: left; /* 文本靠左对齐 */
}

/* 根据屏幕大小进行响应式调整 */
@media (max-width: 1200px) {
    .box {
        width: calc(50% - 20px);
    }
}

@media (max-width: 600px) {
    .box {
        width: 100%;
    }
}
.gotoXls {
    position: fixed;
    padding: 10px;
    background: red;
    border-radius: 15px;
    color: white;
    cursor: pointer;
    display: flex; /* 使用flex布局 */
    justify-content: center; /* 水平居中 */
    align-items: center; /* 垂直居中 */
    text-align: center; /* 确保文本在flex容器中居中 */
    text-decoration: none; /* 如果是链接，去除下划线 */
    transition: transform 0.3s, box-shadow 0.3s; /* 平滑过渡效果 */
}

.gotoXls:hover {
    box-shadow: 3px 4px 8px rgba(86, 77, 77, 0.7);
    transform: scale(0.95); /* 使用transform来缩放，避免改变元素尺寸 */
}
.create-button {
    color: white; /* 文字颜色 */
    border-radius: 50%; /* 圆形按钮 */
    width: 60px; /* 按钮宽度 */
    height: 60px; /* 按钮高度，圆形时宽高应一致 */
    padding: 0; /* 由于是圆形按钮，移除padding，文字居中可能需要其他方法 */
    position: fixed; /* 使用 fixed 定位 */
    bottom: 20px; /* 距离底部的距离，增加以提供更多空间 */
    right: 20px; /* 距离右侧的距离，增加以提供更多空间 */
    z-index: 999; /* 确保按钮在其他内容上方 */
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer; /* 鼠标悬浮时显示手型图标 */
    user-select: none; /* 防止用户选择文本 */
    font-size: 24px; /* 增大文字大小 */
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* 添加阴影，提升按钮层次感 */
}

.create-button:hover {
    background-color: #ff4d4d; /* 悬浮时的背景颜色 */
}
</style>